import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="Страница не найдена" />
    <div className="article" id="error-page">
      <div className="center">
        <section className="header">
          <h1>Страница не найдена</h1>
        </section>
        <section className="content underline">
          <p>
            Мы сожалеем что страница, которую Вы ищете, не существует. Мы хотим
            помочь вам найти те ответы, которые вы ищете. Вы можете вернуться на{' '}
            <a href="/">главную страницу</a> или просмотреть{' '}
            <a href="/temi/">темы</a>.
          </p>
        </section>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
